export const organicseoData = [
    {
        title :'1. What Is SEO And Why Is It Important?',
        des :'SEO is the progressive method of getting a higher spot on the search engine. It is of great importance to consider its impact on the sales, leads, and growth of a business.'
    },
    {
        title :'2. Why Is SEO Important for Business Growth?',
        des :'A business’s growth is directly dependent on its standing in the digital world. SVGB SEO services will improve brand awareness and increase the growth of the business.'
    },
    {
        title :'3. What Are the Two Main Aspects Of SEO?',
        des :'On-page and off-page are the two aspects of a well-structured SEO framework to elevate organic traffic. More than 80% of SEO success is accomplished with the fulfillment of these two parameters.'
    },
    {
        title :'4. What Is the Golden Triangle In SEO?',
        des :'The golden triangle in SEO is the focused area of a user on a search result page from where the conversion rates increase significantly. The golden area comprises more important content of a page.'
    },
    {
        title :' 5. What Is the Most Difficult Part Of SEO?',
        des :'Building backlinks from high domain authority (DA) websites is the most difficult part because high DA websites will only link when on-page content is highly compelling.'
    },
    {
        title :'6. What Are the Stages Of SEO?',
        des :'Research, analysis, planning, execution, reporting, and reevaluation are the main stages of an SEO mechanism.'
    },
    {
        title :'7. What Are the Most Common SEO Mistakes?',
        des :'Poor keyword research, slow site speed, irrelevant internal linking, low-quality content, and content duplication are some of the most common mistakes in an SEO process.'
    },
    {
        title :'8. What Can Hurt Your SEO?',
        des :'Spinning content, low-quality links, keyword cannibalization, and non-responsive website are the factors that can hurt SEO.'
    },
    {
        title :'9. Can I Do SEO On My Own?',
        des :'Yes, you can do SEO on your own, but it will require in-depth knowledge of all the components. SVGB offers expert-level SEO services and can take care of your SEO goals in a progressive manner.'
    },
    {
        title :'10. How Can I Increase My SEO Naturally?',
        des :'Creating relevant, unique, and authoritative content along with perfect interlinking will enhance your SEO naturally.'
    },
    {
        title :'11. How Long Does SEO Take?',
        des :'In general, an SEO strategy takes 3 to 6 months to show organic traffic results. However, consistency is a prerequisite to getting it done within this timeframe.'
    },
    {
        title :'12. What Benefits Can an SEO Agency Provide for My Business IN 2023?',
        des :'An SEO agency like the SVGB I Fremont can provide tailed SEO plans to meet the sales, leads, and growth goals of your business organically.'
    },
    {
        title :'13. How Is Local SEO Different from Organic SEO?',
        des :'Local SEO is the optimization of a business concerning location. In contrast, organic SEO is the methodological approach of optimizing content based on various factors.'
    },
    {
        title :'14. How Much Do SEO Services Cost?',
        des :' SEO services cost $2,000 to $5,000, depending on the scope of services required for a particular website or business.'
    },
    
]

export const webdesignanddevelopment = [
    {
        title :'1.	What To Consider When Choosing a Web Development Company?',
        des :'You should consider the experience, technical stacks, communication, quality conformance, and time management of the web development company.'
    },
    {
        title :'2.	Which Is the Best Web Development Agency in Fremont?',
        des :'SVGB is the leading and most impactful web development agency in Fremont, owing to its diverse experience and top-quality work.'
    },
    {
        title :'3.	Why Is WordPress So Famous?',
        des :'WordPress is a popular CMS and is widely used because it is very easy to manage the websites developed using this CMS. Moreover, it is also highly scalable as it allows custom coding.'
    },
    {
        title :'4.	Why Are Websites Important?',
        des :'Websites are extremely important because they are the digital representation of a business and a great amount of success depends on them.'
    },
    {
        title :'5.	How Long Does It Take to Complete a website?',
        des :'The timeframe required to build a website mainly depends on the fact that it is a custom-coded website or a theme-based website. The custom-coded websites take longer than theme-based websites.'
    },
    {
        title :'6.	Why Is SVGB The Leading Web Development Agency in Fremont?',
        des :'SVGB is the leading web agency because of its technical expertise and continuous improvement approach in this subject.'
    },
    {
        title :'7.	What Are Web Applications?',
        des :'Web applications are quite similar to websites, with the only difference of having a portal and dashboards with different features and functionalities.'
    },
    {
        title :'8.	Which Is the Most Popular Back-end Development Language?',
        des :'PHP is the most popular back-end development language because it is highly scalable and offers immense control to create different high-end functionalities.'
    }
]
//Content Writing Questions
export const contentWriting = [
    {
        title :'1.	Do You Write Free Sample Articles?',
        des :'No, we do not write free samples. However, we offer to write a paid 500-word article if you want to test our skills per your requirements.'
    },
    {
        title :'2.	How Do You Ensure Plagiarism-Free Content?',
        des :'At SVGB, we use multiple high-powered and premium tools for checking plagiarism. We only submit content to clients when we are ensured that it is plagiarism-free.'
    },
    {
        title :'3.	What Happens If I Don’t Like the Article?',
        des :'No problem at all; we will assign it to another writer who will get I done pixel-perfect to your requirements. We offer unlimited revisions unless the client is satisfied.'
    },
    {
        title :'4.	What Is Your Turnaround Time?',
        des :'Generally, our turnaround time is 24 hours. However, it depends on the type, length, and requirements of the content. We also offer expedited turnaround times if you have an emergent requirement.'
    },
    {
        title :'5.	Will I Own the Content You Write for Me?',
        des :'Yes, the moment we deliver the project, you will have copyrights of that piece. We write unique content that is the intellectual property of our clients.'
    }
]