export const aboutData = [
  {
    counterData: [
      {
        exp: "700",
        des: "Projects Completed",
      },
      {
        exp: "20",
        des: "Active \n Clients",
      },
      {
        exp: "300",
        des: "Happy \n Clients",
      },
    ],
    edgeData: [
      {
        title: "Our Mission",
        description: "Our mission is to boost the businesses of our clients through the competitive skills of web development. We aim to develop a long-term relationship with our clients by providing exemplary services and customer support.",
      },
      {
        title: "Our Vision",
        description: "Our vision is to become the prime performer in Silicon Valley with the provision of top-of-the-tier web development and media marketing services. We strive to become the definite force in the global marketplace.",
      }
    ],
    directorData : [
      {
        img : './assets/Pictures/About/profile1.webp',
        title: "Raheel Bodla",
        description: "CEO / Business Coach",
      },
      {
        img : 'assets/Pictures/About/profile2.webp',
        title: "MUZAMMIL BASHIR",
        description: "FULL STACK DEVELOPER / TEAM LEAD",
      },
      {
        img : 'assets/Pictures/About/profile3.webp',
        title: "MONA RAHEEL",
        description: "BUSINESS DEVELOPMENT MANAGER",
      },
      {
        img : 'assets/Pictures/About/profile4.webp',
        title: "ADNAN HASSAN",
        description: "MANAGING DIRECTOR",
      },      
    ],
  },
];
