import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Layout } from "../Layout";
import { subProductData } from "../SubProducts/data";
import { singleProduct } from "./data";
import "./style.scss";
export const SingleProduct = () => {
  const params = useParams();
  const tab = params?.id;
  const filterData = subProductData[0].data.filter((x) => x.singlePage === tab);
  const singleproduct = singleProduct.filter((x) => x.singlePage === tab);
  console.log(singleProduct)
  return (
    <>
      <Helmet>
        <title>Svglobalbusiness | {filterData[0].id === "content" ? filterData[0].Category : filterData[0].title}</title>
      </Helmet>
      <Layout>
        <div className="singleProduct">
          <div className="col-12 position-relative">
            <p className="EurostileExt">
              You are now viewing <Link to="/">Home</Link>
              <span> / </span>
              <Link to="/services">Services</Link>
              <span> / </span>
              <Link to={`/subproducts/${filterData[0].id}`} className="text-capitalize">
                {filterData[0].Category}
              </Link>
              {filterData[0].id === "content" ? (
                ""
              ) : (
                <>
                  <span> / </span>
                  <Link to={`/singleProduct/${filterData[0].singlePage}`} className="text-capitalize">
                    {filterData[0].title}
                  </Link>
                </>
              )}
            </p>
          </div>
          {singleproduct.map((record, key) => (
            <div key={key}>
              <div className="col-12">
                <div className="productCard rounded-3">
                  <h1 className="TisaOT">{record.h1}</h1>
                </div>
              </div>
              {record.layouts.map((layouts, index) => (
                <div key={index}>
                  {layouts.h2 !== "" && <h2 className="TisaOT">{layouts.h2}</h2>}
                  {layouts.layout}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Layout>
    </>
  );
};
