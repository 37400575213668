import { advantage, quantitative, seo_Agency, seo_Fremont, webMechanism, contentMechanism, contentServices } from "./api";
import './style.scss'
export const Layout4 = ({ layoutType, children }) => {
  var cardData;
  switch (layoutType) {
    case "advantage":
      cardData = advantage;
      break;
    case "quantitative":
      cardData = quantitative;
      break;
    case "seo_Agency":
      cardData = seo_Agency;
      break;
    case "seo_Fremont":
      cardData = seo_Fremont;
      break;
    //id web For Website Design and Development
    case "webMechanism":
      cardData = webMechanism;
      break;
    //id content For Content Writing
    case "contentMechanism":
      cardData = contentMechanism;
      break;
    case "contentServices":
      cardData = contentServices;
      break;
      
    default:
      break;
  }
  return (
    <div className="row layout4 layout1 justify-content-center">
      {cardData?.map((record, key) => (
        <div className={`${record.col}`} key={key}>
          <div className=" productCard p-5 text-center d-flex flex-column justify-content-center">
            <h3 className="TisaOT mb-3">
              <b>{record.title}</b>
            </h3>
            <p className="AkkuratLight fs-6 text-center">{record.des}</p>
          </div>
        </div>
      ))}
      {children}
    </div>
  );
};
