import { Textarea } from "@mui/joy";
import { Checkbox, FormControl, FormLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import AlertModal from "../Alert/AlertModal";
import "./style.scss";
import { productData } from "../../pages/Products/data";
const EmailForm = ({ type }) => {
  const value = {
    send: "",
    modalAlert: false,
    msg: "",
    status: "",
  };
  const [data, setData] = useState(value);
  const selectOption = useRef();
  //Handle Select
  const [services, setServices] = useState([]);
  //Handle inputs
  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const handleChangeSelect = (event) => {
    const { value } = event.target;
    setServices(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  //Submit Form
  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();
    setData({ ...data, send: "Sending" });
    try {
      var emailBody;
      // For Contact form Body
      if (type === "contact") {
        //Select Array convert into string//
        var category = "";
        services.map((value) => (category += value + ","));
        data.from_name = category;
        ///////// End //////////
        emailBody = {
          Subject: `Contact for ${category}`,
          Body: `Name  :${data.user_name}<br/>
          Email  :${data.user_email}<br/>
          Phone  :${data.phone}<br/>
          Message  :${data.message}<br/>
          `,
        };
      }
      // For Seo Audit Contact Form Body
      if (type === "seoauditcontact")
        emailBody = {
          Subject: `Contact for SEO Audit `,
          Body: `Name  :${data.user_name}<br/>
        Email  :${data.user_email}<br/>
        Phone  :${data.phone}<br/>
        Site Url for Seo  :${data.siteUrl}<br/>
        `,
        };
      // For Meeting Contact Form Body
      if (type === "contactmeeting")
        emailBody = {
          Subject: `Contact for Schedule A Meeting`,
          Body: `Name  :${data.user_name}<br/>
        Email  :${data.user_email}<br/>
        Phone  :${data.phone}<br/>
        Meeting Time : ${new Date(data.dateTime)}<br/>
        Meeting Platform : ${data.meetingPlateform}<br/>
        Message : ${data.message}<br/>
        `,
        };
      const updatedEmailBody = {
        ...emailBody,
        SecureToken: process.env.REACT_APP_EMAIL_TOKEN,
        To: "waheedahmad99038@gmail.com",
        From: "waheedahmad99038@gmail.com",
      };

      await window.Email.send(updatedEmailBody);
      //Svgb email
      // await emailjs.sendForm("service_yblmfle", "template_oqwambn", form.current, "jdVzIgr-EkRZFl3_L");
      setData({ ...value, status: "success", msg: "Successfully, Email sent !", modalAlert: true });
      clearValues(e.target.reset());
    } catch (error) {
      setData({ ...data, send: "Send", status: "error", msg: "Try again, Email did not sent !", modalAlert: true });
      clearValues();
    }
  };
  const clearValues = () => {
    setTimeout(() => {
      setData({ ...value, modalAlert: false });
    }, 3000);
    setServices([]);
  };

  return (
    <>
      <form className="contactForm about col-sm-12  col-md-5  col-lg-5" ref={form} onSubmit={sendEmail}>
        {data.modalAlert && <AlertModal status={data.status} msg={data.msg} />}
        {/* //For Contact Email */}
        {type === "contact" && (
          <div className="row">
            <h3 className="TisaOT mb-3">Contact Form</h3>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <TextField label="NAME" variant="standard" className="col-12 pe-4 mb-4" name="user_name" onChange={InputEvent} required />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <TextField type={"number"} label="PHONE" variant="standard" className="col-12 pe-4 mb-4" name="phone" onChange={InputEvent} required />
            </div>
            <div className="col-12">
              <TextField type={"email"} label="EMAIL" variant="standard" className="col-12 pe-4 mb-4" name="user_email" onChange={InputEvent} required />
              <FormControl variant="standard" className="col-12 pe-4 mb-4">
                <InputLabel id="demo-simple-select-standard-label">Select Services</InputLabel>
                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={services} onChange={handleChangeSelect} label="Select Service" multiple name="from_name" ref={selectOption}>
                  {productData?.map((record, key) => (
                    <MenuItem key={key} value={record.title}>
                      <Checkbox checked={services.indexOf(record.title) > -1} />
                      {record.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormLabel sx={{ color: "#02b0f1 " }} className="mb-2">
                MESSAGE
              </FormLabel>
              <FormControl className="col-12 pe-4 mb-4" variant="standard">
                <Textarea className="formArea" placeholder="Type Subject" name="message" maxRows={8} minRows={8} required onChange={InputEvent} />
              </FormControl>
              <button className="button py-2 px-5 ">
                {data.send || "Send Message"} <i className="fa fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        )}
        {/* //For Seo Audit Email */}
        {type === "seoauditcontact" && (
          <>
            <h3 className="TisaOT mb-3">Get Audit Report Now!</h3>
            <TextField label="NAME" variant="standard" className="col-12 pe-4 mb-4" name="user_name" onChange={InputEvent} required />
            <TextField type={"email"} label="EMAIL" variant="standard" className="col-12 pe-4 mb-4" name="user_email" onChange={InputEvent} required />
            <TextField type={"number"} label="PHONE" variant="standard" className="col-12 pe-4 mb-4" name="phone" onChange={InputEvent} required />
            <TextField type={"text"} label="SITE URL" variant="standard" className="col-12 pe-4 mb-4" name="siteUrl" onChange={InputEvent} required />
            <button className="button py-2 px-5 mt-4">
              {data.send || "Send Request"} <i className="fa fa-long-arrow-right"></i>
            </button>
          </>
        )}

        {/* //For ContactMeeting Email */}
        {type === "contactmeeting" && (
          <div className="row">
            <h3 className="TisaOT mb-3">Schedule Now!</h3>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <TextField label="NAME" variant="standard" className="col-12 pe-4 mb-4" name="user_name" onChange={InputEvent} required />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <TextField type={"number"} label="PHONE" variant="standard" className="col-12 pe-4 mb-4" name="phone" onChange={InputEvent} required />
            </div>
            <div className="col-12">
              <TextField type={"email"} label="EMAIL" variant="standard" className="col-12 pe-4 mb-4" name="user_email" onChange={InputEvent} required />
              <TextField type={"datetime-local"} label="" variant="standard" className="col-12 pe-4 mb-4" name="dateTime" onChange={InputEvent} required />
              <FormControl variant="standard" className="col-12 pe-4 mb-4">
                <InputLabel id="demo-simple-select-standard-label">Meeting Platform</InputLabel>
                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Meeting Plateform" name="meetingPlateform" onChange={InputEvent}>
                  <MenuItem value={"zoom"}>Zoom</MenuItem>
                  <MenuItem value={"googlemeeting"}>Google Meet</MenuItem>
                </Select>
              </FormControl>
              <FormControl className="col-12 pe-4 mb-4" variant="standard">
                <Textarea className="formArea" placeholder="Type Subject" name="message" maxRows={8} minRows={8} required onChange={InputEvent} />
              </FormControl>

              <button className="button py-2 px-5 mt-4">
                {data.send || "Confirm Meeting"} <i className="fa fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default EmailForm;
