import { useNavigate } from "react-router-dom";

export const NotFound = () => {
    const redirect = useNavigate();
    setTimeout(() => {
        redirect('/')
     }, 1000);
  return (
    <></>
  )
}
