export const Layout1 = ({ para1, para2, img, alt,layoutType, children }) => {
  return (
    <div className={`row layout1 align-items-start ${layoutType===5 && 'flex-row-reverse'}`}>
      <div className={`${layoutType===4 || layoutType===2 ? 'col-12':'col-sm-12 col-md-8 col-lg-8'}`}>
        <p className="AkkuratLight fs-5">{para1}</p>
        {
          layoutType===4 && <span style={{ color: "#faa731" }} className="fs-4">
          <b>Results, Results, Results</b>
        </span>
        }
      </div>
      {layoutType!==4 && <div className={`text-center ${layoutType===2 ? 'col-12':'col-sm-12 col-md-4 col-lg-4'}`}>
        <img src={img} alt={alt} className={`img-fluid ${layoutType===2 && 'w-75'}`}/>
      </div>}
      <div className="col-12">
        <p className="AkkuratLight fs-5">{para2}</p>
        {children}
      </div>
    </div>
  );
};
