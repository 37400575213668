import { createBrowserRouter } from "react-router-dom";
import { NotFound } from "../pages/404/NotFound";
import { About } from "../pages/About/About";
import { Contact } from "../pages/Contact/Contact";
import { ContactMeeting } from "../pages/ContactMeeting/ContactMeeting";
import { ContactSeoAudit } from "../pages/ContactSeoAudit/ContactSeoAudit";
import { LandingPage } from "../pages/LandingPage/LandingPage";
import  {Portfolio}  from "../pages/Portfolio/Portfolio";
import { Products } from "../pages/Products/Products";
import { SingleProduct } from "../pages/SingleProduct/SingleProduct";
import { SubProducts } from "../pages/SubProducts/SubProducts";
export const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/about",
      element: <About />,
    },{
      path: "/services",
      element: <Products />,
    },{
      path: "/subproducts/:id",
      element: <SubProducts />,
    },{
      path: "/contact",
      element: <Contact />,
    },{
      path: "/portfolio",
      element: <Portfolio />,
    },{
      path: "/seoauditcontact",
      element: <ContactSeoAudit />,
    }
    ,{
      path: "/contactmeeting",
      element: <ContactMeeting />,
    },{
      path: "/singleproduct/:id",
      element: <SingleProduct />,
    },{
      path: "*",
      element: <NotFound />,
    }
  ]);