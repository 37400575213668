import './style.scss'
const Subheader = () => {
  return (
    <div className='subheader'>
      <div className="container-width">
        <span><i className='fa fa-envelope'></i> info@svglobalbusiness.com</span>
        <span><i className='fa fa-phone'></i>+1 (510) 574-7757</span>
      </div>
    </div>
  )
}

export default Subheader
