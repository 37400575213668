import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
//Animation css 
import "react-on-scroll-animation/build/index.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <App />
);

