export const data = [
    {
        navbar : [
            {
                num:'01',
                navItem : 'Home',
                link : '/'
            },
            {
                num:'02',
                navItem : 'About Us',
                link : '/about'
            },
            {
                num:'03',
                navItem : 'Portfolio',
                link : '/portfolio'
            },
            {
                num:'04',
                navItem : 'Services',
                link : '/services'
            },
            {
                num:'05',
                navItem : 'Contact Us',
                link : '/contact'
            },
            {
                num:'06',
                navItem : 'Schedule A Meeting',
                link : '/contactmeeting'
            },
            {
                num:'07',
                navItem : 'Get a Free SEO Audit',
                link : '/seoauditcontact'
            },
        ]
    }
] 
