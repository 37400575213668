import { Cta } from "../../components/SinglePage/Cta";
import { Layout1 } from "../../components/SinglePage/Widget/Layout1";
import { Layout2 } from "../../components/SinglePage/Widget/Layout2";
import { Layout3 } from "../../components/SinglePage/Widget/Layout3/Layout3";
import { Layout4 } from "../../components/SinglePage/Widget/Layout4/Layout4";
import { QAccordion } from "../../components/Accordion/QAccordion";
import { Link } from "react-router-dom";

const serverName = `${window.location.protocol + "//" + window.location.host}`;
export const singleProduct = [
  //Web for web developemnt and design
  {
    id: "web",
    singlePage: "full-stack-development",
    h1: "Top-Tier Full Stack Development Services in Fremont, CA",
    layouts: [
      //layout 1
      {
        h2: "SVGB is the pillar of excellence in providing web development services in Fremont. Our ideas and conceptualization make us poles apart from the other agencies.",
        layout: (
          <Layout1 layoutType={2} para1={"Full Stack Development is the digital essence of a business in this world. SVGB is the curator of the digital reality of globally leading companies. You have an idea, and we have the force required to turn that idea into reality. Every milestone in the digital spectrum begins with a top-notch digital presence. A website is the most basic entity to enlist a business within the digital world.\n\n We are one of the best full-stack companies in Fremont that have exceptional skills to cater to any project, irrespective of the complexity. Our affordable web design and development services are the two echelons that have yielded immense success for our honorable clients."}>
            {/* //Contact us  */}
            <Cta title={"Get The Most Affordable Full Stack Development Services. Contact SVGB Now!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        ),
      },
      //layout 2
      {
        h2: "SVGB’s Core Web Development Services in Fremont",
        layout: <Layout1 layoutType={2} para1={"SVGB is highly adept in different technical stacks that let us achieve the highest levels of success for our clients. Our diverse expertise in the subject of digital development has transformed us into one of the best full-stack web development companies in Fremont. We use the latest technologies to design and develop the best web solutions for our clients."} img={`${serverName}/assets/Pictures/Services/SVGB-Core-Web-Development-Services-in-Fremont.jpg`} alt="web development services in fremont " />,
      },
      //layout 3
      {
        h2: "E-commerce Development Services",
        layout: (
          <Layout1 layoutType={1} para1={"The post-covid world has seen a dramatic increase in e-commerce businesses. SVGB is at the helm of affairs when it comes to providing Shopify e-commerce development services. Moreover, our WordPress e-commerce development services are also top-notch in terms of industry standards.\n\n"} para2={"Whether it’s an online gift shop, clothing brand, or home improvement shop, our B2B web design agency has distinguished experience in developing high-end e-commerce websites with tailored solutions.\n\n"} img={`${serverName}/assets/Pictures/Services/E-commerce-Development-Services.jpg`} alt="ecommerce programming and coding">
            {/* //Contact us  */}
            <Cta title={"Need E-Commerce Web Development? SVGB Is Ready To Roll Your Brand Out!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        ),
      },
      //layout 1
      {
        h2: "PHP Web Development Services",
        layout: (
          <Layout1 layoutType={5} para1={"PHP is the most dynamic programming language for creating highly factionalized websites. SVGB is jam-packed with a team of web developers for PHP who are masters of this technology. PHP is one of the most influential languages used in modern web development mechanisms.\n\nA top-rated team of PHP developers makes us the leading-edge firm in Fremont. We utilize PHP for back-end development to meet the specific requirements of clients."} img={`${serverName}/assets/Pictures/Services/PHP-Web-Development-Services.jpg`} alt="php development">
            {/* //Contact us  */}
            <Cta title={"Let’s Build Your Website. Contact Us For A Free Quote!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        ),
      },
      //layout 1
      {
        h2: "Laravel Web Development Services",
        layout: (
          <Layout1 layoutType={1} para1={"Laravel is a PHP framework that is instrumental in creating user-friendly web applications. SVGB is a Fremont-based Laravel web development agency that can realize the dreams of clients’ web application visions. We will utilize the up-to-date practices for moving forward with the development process.\n\n Our B2B website design agency will set your online business up on the top level in the digital world. We will incorporate exceptional web design strategies to chalk out the framework of your business’s digital presence.\n\n"} img={`${serverName}/assets/Pictures/Services/Laravel-Web-Development-Services.jpg`} alt="Laravel web development service">
            {/* //Contact us  */}
            <Cta title={"Let’s Turn Your Ideas Into Reality. Reach Out To SVGB Now!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        ),
      },
      //layout 1
      {
        h2: "WordPress Web Development",
        layout: (
          <Layout1 layoutType={2} para1={"WordPress is one of the most popular CMS that accounts for more than 40% of websites that are in the digital hemisphere. This CMS-based platform provides thousands of themes and plugins for web development per very specific requirements.\n\nSVGB has a highly skilled team of WordPress developers who can build websites for clients from scratch in WordPress CMS. We have in-depth knowledge of all building block elements required to formulate dynamic WordPress websites.\n\n"} img={`${serverName}/assets/Pictures/Services/wordpress-web-development.jpg`} alt="svgb wordpress web development">
            {/* //Contact us  */}
            <Cta title={"Need WordPress Web Development Services In Fremont? Get In Touch With SVGB!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        ),
      },

      //layout 3
      {
        h2: "What Makes Us the Best Web Application Agency in Fremont?",
        layout: <Layout3 layoutType={"makeWEbApplication"} />,
      },
      //layout 1
      {
        h2: "SEO Optimized Website",
        layout: (
          <Layout1 layoutType={2} para1={"SEO-optimized website is the most important component of our web development strategy. SEO is the content portion of a website that will help it to achieve top rankings in Google search against specific keywords. It is also the well-structured layout of the website per the best practices.\n\n SVGB will ensure the best methodologies to optimize every nook and corner of the website in terms of SEO. Resultantly, it will yield higher rankings on Google, increasing sales and revenue significantly. An SEO-empowered website is the turning point for a business."}>
            {/* //Contact us  */}
            <Cta title={"Ready To Launch Your Business Online? Contact SVGB For A Head Start!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        ),
      },
      //layout 15
      {
        h2: "",
        layout: (
          <>
            <h2 className="text-center TisaOT ">Our Clients Love Working with Us</h2>
            <h4 style={{ marginTop: "-2rem" }} className="text-center TisaOT">
              Trustworthy Relationship with Clients Is Our Priority.
            </h4>
            <br />
            <div className="row layout1">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className=" productCard p-5 text-justify">
                  <p className="AkkuratLight fs-6 text-justify">{"I had the chance to work with SVGB on an extremely complex web development project involving the creation of functional dashboards. They went above and beyond in providing a turnkey solution for my project, and our organization saw a sharp increase in customers due to the super simple UI/UX design and seamless operations.\n"}</p>
                  <b className="TisaOT mb-3">Matt Alison</b>
                  <br />
                  <b className="TisaOT mb-3">Gravity Investments LLC</b>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className=" productCard p-5 text-justify">
                  <p className="AkkuratLight fs-6 text-justify">We require customized features for our online Auto store. It was a combination of different functions along with database integration to display the relevant information. SVGB was very quick in explaining to us the different options we had to get it done. Within no time, we got the solution, and everything was smooth, including communication and maintenance.</p>
                  <b className="TisaOT mb-3">Gray Nicolson</b>
                  <br />
                  <b className="TisaOT mb-3">DV Motorsports</b>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        h2: "SVGB’s Web Development Services Mechanism",
        layout: <Layout1 layoutType={2} para1={"Our Fremont-based web development company follows a well-structured and interlinked mechanism to develop high-end websites. The clear-cut categorization of this process is the reason behind our highly acclaimed website development services."} />,
      },
      //layout 4
      {
        h2: "",
        layout: (
          <Layout4 layoutType={"webMechanism"}>
            {/* //Contact us  */}
            <Cta title={"Ready To Launch Your Website? SVGB Will Assist You With Take-Off!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout4>
        ),
      },
      //layout 23
      {
        h2: "Frequently Asked Questions",
        layout: <QAccordion layoutType={"webdesignanddevelopment"} />,
      },
    ],
  },
  //Content writing
  {
    id: "content",
    singlePage: "content",
    h1: "Content Writing Services in Fremont: A Business Booster",
    layouts: [
      //layout 1
      {
        h2: "Save Time and Money by Delegating Content Writing To SVGB!",
        layout: (
          <Layout1 layoutType={2} para1={"Wordsmithing is the creative edge of SVGB’s highly professional team of content writers. We provide the most dynamic, business-centric, and reader-friendly SEO content services in Fremont, and that too, at exceptionally low rates. Moreover, we also cater to social media content writing services to bolster the digital presence of businesses."}>
            {/* //Contact us  */}
            <Cta title={"Want To Upscale Your Content Strategy? Contact SVGB Now!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        ),
      },
      //layout 2
      {
        h2: "What Is Content Writing in True Sense?",
        layout: <Layout1 layoutType={2} para1={"Content writing is the art of putting words at work to engage the readers and compel them to a certain business product or indulge knowledge. SVGB provides the best SEO content writing services that are bound to uptick the growth of a business by getting higher organic traffic than the competitors.\n\nSEO-friendly content creation is the topmost functional aspect of content services. The optimization of the web content per the latest SEO practices will enhance the business standings in Google search results. We are an SEO copywriting company that has immense control over the usage of words.\n\n"} para2={"\nArticle writing is a well-researched pattern of thoughts to deliver a message or to entrust the readers with the authenticity of an idea. SEO content is the main power-packed pillar of any type of content. It is a unique strategic structure that empowers the content to rank higher in the SERPs.\n\nContent creation is the major branding process of a business entity as it will keep the customers informed about the latest products and services of the business. Dynamic, product-optimized, and SEO-powered content will act as a benchmark tool for increasing conversion rates through the sales funnel."} img={`${serverName}/assets/Pictures/Services/silicon-valley-global-businessb-content-writing-services-in-fremont.svg`} alt="silicon-valley-global-businessb-content-writing-services-in-fremont" />,
      },
      //layout 3
      {
        h2: "SEO Content Writing and SEO Content Marketing",
        layout: <Layout1 layoutType={1} para1={"SEO content writing and SEO content marketing are two interlinked processes. SVGB is the leading SEO copywriting agency in Fremont that provides top-notch content with marketing services through proper channels for attracting a targeted audience.\n\nSEO writing is the predecessor of SEO content marketing. Content marketing services are established to increase the reach of SEO-enabled content. The marketing strategy of the content is based on social media writing services to expand the scope of the business to a wider digital audience. SEO and content marketing overlap each other to boost conversion rates.\n\n"} para2={"We know the channels most effective for upscaling the marketing spectrum of SEO content. Therefore, we can shape the blog posts and website content to meet the specific targets of your business. The implementation of robust SEO content writing and SEO content marketing strategies will increase organic traffic significantly.\n\n"} img={`${serverName}/assets/Pictures/Services/SEO-Content-Writing-and-SEO-Content-Marketing.svg`} alt="SEO-Content-Writing-and-SEO-Content-Marketing">
            {/* //Contact us  */}
            <Cta title={"Need A Customized SEO Content Strategy? Schedule A Free Demo Right Away!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
      },
      //layout 1
      {
        h2: "Why Are Content Writing Services in the USA Important?",
        layout: <Layout1 layoutType={2} para1={"SEO content services are overwhelmingly important for businesses with the continuous increase in competition. Every business sector is developing with the latest technological inventions. Thereby, the competition is also at a record high, and only those with higher digital standings will lead the customer base."} />
      },
      //layout 1
      {
        h2: "",
        layout: (
          <>
            <h3>1) Increasing Customer Base</h3>
            <Layout1 layoutType={2} para1={"The most crucial impact of SVGB’s SEO blog writing services is that it will increase the customer base sharply. We undertake a comprehensive research mechanism to chalk out the most relevant and authentic keywords per your business requirements.\nThis strategic content creation approach of our copywriting agency is paramount for increasing the customers and e retention rates. Consequently, the scope of business increases to a great length."}  />
          </>
        ),
      },
      //layout 1
      {
        h2: "",
        layout: (
          <>
          <h3>2) Ranking Higher on SERPs</h3>
          <Layout1 layoutType={2} para1={"SVBG provides e-commerce content writing services in Fremont that will change the course of rankings of a business in the digital spectrum. More than 75% of customers will purchase products from businesses that are shown on the first page of Google search results.\nTherefore, SEO-powered content is highly impactful in bringing business rankings to the top of the SERPs. Greater ranking will yield more customers, resulting in higher sales."} />
        </>
        ),
      },
      {
        h2: "",
        layout: (
          <>
          <h3>3) Bolstering Brand Authority</h3>
          <Layout1 layoutType={2} para1={"The development of brand authority takes time, commitment, and consistency with a calculated effort in content creation. SVGB curates the content in such a way as to build brand authority continuously. The continual improvement of content will prove imperative for enhancing the authority of a business.\nWe provide the best SEO copywriting services that have paved the way for businesses to become dominant brands in their respective niches. Thus, high-quality and optimized content plays a crucial role in the development of the business."} />
        </>
        ),
      },
      {
        h2: "",
        layout: (
          <>
          <h3>4) Multiplying Conversion Rates</h3>
          <Layout1 layoutType={2} para1={"Conversion rates are the statistical components that define the compelling force of the content. We write blog posts that are highly informative and are sure bound to attract high domain authority backlinks. Thereby, these linking domains will increase the online authority of the business.\nConsequently, it will amplify the conversion rates by multiple folds. Conversion rates are the measuring parameters of a business venture’s success."}/>
        </>
        ),
      },
      {
        h2: "",
        layout: (
          <>
          <h3>5) Increasing Revenues</h3>
          <Layout1 layoutType={2} para1={"Revenue is the end outcome of any business through content creation and content marketing strategies. SVGB’s blog writing services packages tend to increase revenues by a great margin. Our user-focused content creation will increase the rankings of web content in the SERPs.\nThus, it will bring in more organic traffic through multiple channels, resulting in greater conversion rates and higher revenues. High-quality content is the epitome of business growth in a highly challenging and competitive digital world."}>
          {/* //Contact us  */}
          <Cta title={"Ready To Increase Your Conversion Rates 5 Times? Get In Touch with SVGB Right Away!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout1>
        </>
        ),
      },

      //layout 3
      {
        h2: "What Is Included in SVGB’s SEO Content Services?",
        layout: <Layout3 layoutType={"contentservices"}>
         {/* //Contact us  */}
         <Cta title={"Get The Best Website Copywriting Services to Increase Growth. Contact SVGB Now!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout3>,
      },
      {
        h2: "SVGB Content Writing Mechanism",
        layout: <Layout1 layoutType={2} para1={"Content writing is a structured process and has to be done accordingly to yield the best results in terms of enhancing organic traffic. We have devised a specialized mechanism to create the most dynamic pieces of writing."} />,
      },
      //layout 4
      {
        h2: "",
        layout: (
          <Layout4 layoutType={"contentMechanism"}>
            {/* //Contact us  */}
            <Cta title={"Create Attention-Grappling Content. Let’s Get It Done!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout4>
        ),
      },
      {
        h2: "SVGB’s Content Writing Services in Fremont",
        layout: <Layout1 layoutType={2} para1={"Content writing is not a single entity, as it has several different types. Every writing category has a different subset of rules and regulations. At SVGB, we are adept in all the below-listed content categories:"} />,
      },
      {
        h2: "Blog Writing",
        layout: <Layout1 layoutType={2} para1={"Blog posts make up the online reliability of a brand or a business. A consistent blog section within a website builds brand authority and will increase organic traffic significantly. SVGB is adept in providing blog writing services packages per your specific niche. We will create content that will hit the targeted audience and will yield higher conversion rates."}>
         {/* //Contact us  */}
         <Cta title={"Get Your Blog Active and Running. Hire Our Blog Writing Services!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Technical Writing",
        layout: <Layout1 layoutType={2} para1={"The technical writing services of SVGB aim to explain solutions to technical problems in a user-friendly approach. SaaS content writing is a subdivision of technical writing. We have a powerhouse of technical writers who can comprehend complex industrial problems and can write a dynamic piece showcasing the solutions in a comprehendible format."}>
         {/* //Contact us  */}
         <Cta title={"Need Help With Technical Writing? SVGB Is Here To Assist You!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Product Description Writing",
        layout: <Layout1 layoutType={2} para1={"SEO product description writing services are quite different from conventional writing services. A product description is straightforward and informative, covering all the product features. Whether you have an online business over Amazon, Target, Walmart, Alibaba, WooCommerce, eBay, or Shopify, our product description writing services will boost the sales of your online business."}>
         {/* //Contact us  */}
         <Cta title={"Make Product Descriptions More Impactful. Let Us Write Your Products Story!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Academic Writing",
        layout: <Layout1 layoutType={2} para1={"In-depth research and cited resources are the fundamental requirements for academic content writing. This type of writing is based on research papers or scholarly articles. SVGB’s team of highly educated academic writers can undertake any project of research writing and will provide the most dynamic and power-packed solutions will proper citations from relevant resources."}>
         {/* //Contact us  */}
         <Cta title={"Don’t Have Time To Write Research Papers? Leave It To SVGB!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Website Copywriting",
        layout: <Layout1 layoutType={2} para1={"SVGB offers the best website copywriting services in Fremont. Website content is the first impression on a user. Therefore, it has to be pitch-perfect in terms of quality and understanding. Content writing for e-commerce websites is also a part of copywriting services. Let us hold the rein of your website content to enhance the leads and sales of your online business."}>
         {/* //Contact us  */}
         <Cta title={"Want Copywriting To Increase Leads? Allow Us To Put Our Words Into Action!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Whitepaper Writing",
        layout: <Layout1 layoutType={2} para1={"Whitepaper writing services entail the use of theoretical and practical approaches for solving complex problems. This is a technical and well-researched approach to writing content. We utilize a robust strategy for developing whitepaper content that is instrumental in providing simplistic solutions to complex problems. Our whitepaper copywriters follow a user-centric approach."}>
         {/* //Contact us  */}
         <Cta title={"Comprehensive Whitepapers and Guides? Count On SVGB!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Newsletter Writing",
        layout: <Layout1 layoutType={2} para1={"Newsletters are easy and authentic methodologies for developing a long-term relationship with the targeted audience. SVGB’s newsletter writing service is straight out of the box and will relay important messages to customers using short script text. We will incorporate highly powerful hook words for creating high-end newsletters to increase a business’s customer base."}>
         {/* //Contact us  */}
         <Cta title={"Increase Customer Retention Rates. Get Customized Newsletters Now!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "SEO Content Writing",
        layout: <Layout1 layoutType={2} para1={"SEO content writing is specifically targeted to achieve the top on the search engine result pages. It encompasses keyword density and distribution optimization, structured headings, and high-quality content per the targeted keywords. We will bring into force the latest SEO development to optimize the content that will out-rank the competitors to a great extent."}>
         {/* //Contact us  */}
         <Cta title={"Want To Make It To The Top Of Search Engines? Hire SVGB!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Real Estate Content Writing",
        layout: <Layout1 layoutType={2} para1={"Real estate content is different from product descriptions in terms of layout and specific requirements. SVGB knows the dynamics of the real estate industry and understands the customers' expectations when they visit a real estate website. Thus, we will configure the best real estate content that will encourage the customers, increasing the click-through rates."}>
         {/* //Contact us  */}
         <Cta title={"Increase Your Click-Through Rates. Get In Touch With SVGB!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
        </Layout1>,
      },
      {
        h2: "Why Choose SVGB For Unlimited Content Writing Services?",
        layout: <Layout1 layoutType={2} para1={"SVGB is a leading firm that excels in providing a diverse range of content writing services. We offer a significant number of content types with the topmost strategies to make them effective. There is a reason we are considered the best SEO copywriting agency in Fremont."}></Layout1>
      },
      {
        h2: "",
        layout: (
          <Layout4 layoutType={"contentServices"}>
            {/* //Contact us  */}
            <Cta title={"Get The Most Impact Content. Reach Out To SVGB Now!"} startButton={"GET STARTED"} contactButton={"CONTACT US"} />
          </Layout4>
        ),
      },
      //layout 15
      {
        h2: "",
        layout: (
          <>
            <h2 className="text-center TisaOT ">SVGB – A Trusted Content Writing Agency</h2>
            <h4 style={{ marginTop: "-2rem" }} className="text-center TisaOT">
            We have won the admiration and trust of our clients through a uniform ad client-centric content creation strategy. We go above and beyond in realizing the content goals of our clients.
            </h4>
            <br />
            <div className="row layout1">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className=" productCard p-5 text-justify">
                  <h3 className="text-center TisaOT mb-2">E-Commerce Marketplace Website</h3>
                  <p className="AkkuratLight fs-6 text-justify">We have a gigantic marketplace with thousands of products, but still, we were lagging in revenue goals due to poor content management. SVGB was a silver lining for our online business, and their content strategy has made us a living example of success.</p>
                  <b className="TisaOT mb-3">+247% Organic Traffic | +320% Sales | +170% Returning Customers</b>

                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className=" productCard p-5 text-justify">
                  <h3 className="text-center TisaOT mb-2">Technology Blog</h3>
                  <p className="AkkuratLight fs-6 text-justify">My technology blog was down on page seven of SERPs, and the vital statistics were at rock bottom. The tech geeks at SVGB were amazingly superb in revamping m blog and attaining getting it on the first page. Over time, my blog’s domain authority has also increased.</p>
                  <b className="TisaOT mb-3">+315% Search Clicks | +759% Impressions | -192% Bounce Rate</b>
                </div>
              </div>
            </div>
          </>
        ),
      },
      //layout
      {
        h2: "Frequently Asked Questions",
        layout: <QAccordion layoutType={"contentWriting"} />,
      },
    ],
  },
];
