import "./style.scss";
import { Link } from "react-router-dom";
import { Layout } from "../Layout";
import { Helmet } from "react-helmet";
import EmailForm from "../../components/EmailForm/EmailForm";
export const ContactMeeting = () => {
  
  return (
    <>
      <Helmet>
        <title>Svglobalbusiness | Contact Meeting</title>
      </Helmet>
      <Layout>
      <div className="ContactMeeting">
        <div className="subcontainer row justify-content-md-between">
          <div className="col-sm-12  col-md-5  col-lg-5 position-relative">
            <p className="EurostileExt">
              You are now viewing <Link to="/">Home</Link>
              <span> / </span>
              <Link to="/contactmeeting">Schedule A Meeting</Link>
            </p>
            <div className="content">
              {/* <img className="w-100" src="assets/Pictures/contact.svg" alt="" /> */}
              <div className="title mt-4">
                <h1 className="TisaOT my-4">Schedule A Meeting</h1>
                <p className="AkkuratLight">Let's Discuss Your Project! <br/><br/> Fill Out the Form to Get in Touch with Our Technical Support.</p>
              </div>
            </div>
          </div>
          {/* Form Contact us  */}
          <EmailForm type={'contactmeeting'}/>
        </div>
      </div>
      
    </Layout>
    </>
    
  );
};
