export const record = [
    {
        title:'Web Development',
        des:'',
        category :'website',
        img : 'assets/Pictures/Portfolio/1.webp'
    },
   
    {
        title:'Seo',
        des:'',
        category :'website',
        img : 'assets/Pictures/Portfolio/2.webp'
    },
    {
        title:'Web Development',
        des:'',
        category :'website',
        img : 'assets/Pictures/Portfolio/3.webp'
    },
    {
        title:'Web Development',
        des:'',
        category :'website',
        img : 'assets/Pictures/Portfolio/4.webp'
    },
    {
        title:'Web Development',
        des:'',
        category :'website',
        img : 'assets/Pictures/Portfolio/5.webp'
    },
    {
        title:'Web Development',
        des:'',
        category :'website',
        img : 'assets/Pictures/Portfolio/6.webp'
    },
]