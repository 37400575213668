import React from "react";
import "./style.scss";
import { fadeInRight, bounceInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Link, useLocation } from "react-router-dom";
import { data } from "../componentData";
const animate = {
  fadeInRight: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRight, "fadeIn"),
  },
  bounceInUp: {
    animation: "x 3s",
    animationName: Radium.keyframes(bounceInUp, "bounce"),
  },
};
export const DesktopNavbar = () => {
  // ServerName
  const serverName = `${window.location.protocol + "//" + window.location.host}`;
  const location  = useLocation();
  return (
    <StyleRoot>
     <div style={animate.fadeInRight} className="desktopNavbar">
        <div className="container">
        <Link to='/'><img src={`${serverName}/assets/Pictures/logo.webp`} alt="logo" className="logo"/></Link>
        </div>
        <div className="desktopNav">
        <div className="container d-flex justify-content-between">
        {
          data[0]?.navbar.slice(0,5).map((record,index) => (
              <div key={index} style={animate.bounceInUp} className="d-flex flex-column nav">
              <span className="mb-0 EurostileExt">{record.num}</span>
              <Link className={`${location.pathname === record.link ? 'active':''} TisaOT`} to={record.link} >{record.navItem}</Link>
          </div>
            
          ))
        }
        </div>
        </div>
      </div>
    </StyleRoot>
  );
};
