import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { organicseoData, webdesignanddevelopment, contentWriting } from "./api";

export const QAccordion = ({ layoutType }) => {
  var records;
  switch (layoutType) {
    case 'organicseo':
      records = organicseoData
      break;
      case 'webdesignanddevelopment':
      records = webdesignanddevelopment
      break;
      case 'contentWriting':
      records = contentWriting
      break;
      
    default:
      break;
  }
  return (
    <div className="row layout1">
      <div className="col-12">
        {records?.map((record, key) => (
          <Accordion key={key}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h5" className="TisaOT">
                {record.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="AkkuratLight" variant="p">
                {record.des}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
