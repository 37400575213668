export const on_Page = [
  {
    item: "On-Page SEO services in Fremont are the process of optimizing webpages to drive higher levels of organic traffic. SVBG takes an in-depth consideration of all the contributing factors of on-page SEO.",
  },
  {
    item: "On-page SEO majorly involves keyword research, high-quality content creation based on keywords, and internal linking of webpages to present a structured website for search engine crawlers.",
  },
  {
    item: "Ensuring optimum keyword density and distribution are two of the most fundamental vitals of getting the best out of an on-page SEO strategy. On-page SEO accounts for more than 60% significance within the SEO frameworks.",
  },
];

export const off_Page = [
  {
    item: "Building high-quality and high domain authority (DA) backlinks are the main workflow methodology of off-page SEO. The links pointing back to your website must have good domain authority and content; otherwise, they can be damaging.",
  },
  {
    item: "SVGB local SEO services in Fremont take an unconventional approach to create high-end do-follow backlinks that are not only of high DA but are also relevant to your business/website niche.",
  },
  {
    item: "There are several ways to develop a backlinking strategy that includes but are not limited to guest posts, social bookmarking, and business listing.",
  },
];
export const rating_Page = [
  {
    item: "A local business becomes credible with reviews and ratings. These are the honest opinions of customers about a particular business. A business rating is the direct measure of brand authority within a particular demographic.",
  },
  {
    item: "SVGB is a Fremont SEO company that will build the authority and awareness of your local business to boost reviews and ratings.",
  },
  {
    item: "Optimization of Google Business Profile is an influential factor in getting organic search rankings for a local business in Fremont. We ensure the optimization of your GBP to build up the organic ranking o your local business.",
  },
];

export const technical_Page = [
  {
    item: "Technical SEO is the optimization of URLs, page speed, meta tags, meta titles, meta descriptions, and core web vitals.",
  },
  {
    item: "Moreover, a technical SEO mechanism will also incorporate the creation of an HTML site map and XML sitemap, which are essential indicators for a search engine web crawler.",
  },
  {
    item: "Indexing and crawling issues are also dealt with as part of technical SEO. SVGB offers a comprehensive approach to revitalizing technical SEO. We are an expert in Fremont that has an unmatched grip on the factors decisive for technical SEO.",
  },
];

export const practice_Page = [
  {
    item: "SVGB’s search engine optimization services in Fremont utilize the best practices to thrive the local businesses.",
  },
  {
    item: "We initiate our SEO plan by carrying out a pinch-perfect analysis of your business competitors.",
  },
  {
    item: "On-page optimization is the first and foremost component of an SEO mechanism, as it accounts for more than 60% of SEO ranking factors.",
  },
  {
    item: "We formulate the best keyword research, content creation, content optimization, and internal linking strategies to devise an undeterred on-page SEO PLAN.",
  },
  {
    item: " We keep an eagle’s eye on the latest updates of search algorithms as they will change the SEO mapping strategies.",
  },
  {
    item: "Our structured SEO approach is based on transparent and white-hat methodologies that produce high-quality and long-term results.",
  },
];
export const expect_services = [
  {
    item: "More Organic Traffic",
  },
  {
    item: "More Revenue",
  },
  {
    item: "More Brand Awareness",
  },
  {
    item: "More Business Growth",
  },
  {
    item: "More Trust and Reliability",
  },
];
//makeWEbApplication for web website design and developement
export const makeWEbApplication = [
  {
    item: "Client-Centric Approach",
  },
  {
    item: "Estimating Web Design Solution",
  },
  {
    item: "Time-Effective Project Management",
  },
  {
    item: "On-Budget Completion of Projects",
  },
  {
    item: "Responsive Design Strategies",
  },
  {
    item: "Tailored Solutions and Features",
  },
  {
    item: "Scalable Web Development Solutions",
  },
  {
    item: "24/7 Technical Support",
  },
  {
    item: "Maintenance Services",
  },
  {
    item: "Secure Website",
  },
  {
    item: "SEO-Powered Web Design",
  },
  {
    item: "Highly Intuitive UI/UX Design",
  },
];
    //Content Writing
export const contentservices= [
  {
    item: "Comprehensive Competitor Analysis",
  },
  {
    item: "In-Depth Keyword Research per the Targeted Audience",
  },
  {
    item: "Blogs Posts Based on Researched Keywords While Ensuring Optimum Keyword Density",
  },
  {
    item: "High-Quality Feature Article",
  },
  {
    item: "Image Optimization",
  },
  {
    item: "URLs Optimization",
  },
  {
    item: "Technical SEO Improvements",
  },
  {
    item: "Website Speed Optimization",
  },
  {
    item: "Generating High Domain Authority Backlinks",
  },
  {
    item: "Guest Posts",
  },
  {
    item: "Social Media Posts",
  },
  {
    item: "Reporting SEO Content Results Bi-Monthly",
  },
];