import { expect_services, makeWEbApplication, off_Page, on_Page, practice_Page, rating_Page, technical_Page,contentservices } from "./api";

export const Layout3 = ({ title, para, layoutType, children }) => {
  var records;
  switch (layoutType) {
    case "on_Page":
      records = on_Page;
      break;
    case "off_Page":
      records = off_Page;
      break;
    case "rating_Page":
      records = rating_Page;
      break;
    case "technical_Page":
      records = technical_Page;
      break;
    case "practice_Page":
      records = practice_Page;
      break;
    case "expect_services":
      records = expect_services;
      break;
    //makeWEbApplication for web website design and developement
    case "makeWEbApplication":
      records = makeWEbApplication;
      break;
    //Content Writing
    case "contentservices":
      records = contentservices;
      break; 
    default:
      break;
  }
  return (
    <div className="row layout1">
      <div className="col-12">
        {layoutType !== "expect_services" ? <h3>{title}</h3> : <p className="AkkuratLight fs-5 text-justify">{para}</p>}
        <ul className="AkkuratLight fs-5" style={{ listStyle: "none" }}>
          {records?.map((record, key) => (
            <li key={key}>
              <i className={`fa ${layoutType !== "expect_services" ? "fa-check" : "fa-long-arrow-up"}`}></i>
              {record.item}
            </li>
          ))}
        </ul>
        {children}
      </div>
    </div>
  );
};
