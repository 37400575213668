import { Link } from "react-router-dom";
import { productData } from "../../pages/Products/data";
import { data } from "../componentData";
import "./style.scss";
export const Footer = () => {
  return (
    <div className="footer row mt-5">
      <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
        <div className="box">
          <h4 className="EurostileExt">ABOUT </h4>
          <p className="mb-3">SVGB is the top-tier web development agency in the digital ecosystem of Silicon Valley.</p>
          <p>3661 Kay Ct Fremont, CA 95438</p>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
        <div className="box">
          <h4 className="EurostileExt">LINKS </h4>
          {data[0]?.navbar.map((record, key) => (
            <div key={key}>
              <Link to={record.link}>{record.navItem}</Link>
              <br />
            </div>
          ))}
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
        <div className="box">
          <h4 className="EurostileExt">SERVICES </h4>
          {productData?.map((record, key) => (
            <div key={key}>
              <Link to={`/subproducts/${record.id}`} key={key}>
                {record.title}
              </Link>
              <br />
            </div>
          ))}
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
        <div className="box">
          <h4 className="EurostileExt">SUPPORTS </h4>
          <>
            <Link to={`/contact`}>Contact Us</Link>
            <br />
            <br />
            <br />
            <p>Phone: +1 (510) 574-7757</p>
            {/* <p> Phone: +92 03134774777</p>           */}
            <p>info@svglobalbusiness.com</p>
          </>
        </div>
      </div>
    </div>
  );
};
