import "./style.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { Helmet } from "react-helmet";
export const LandingPage = () => {
  const redirect = useNavigate();
  const handleOnWheel = () => {
    setTimeout(() => {
      redirect("/services");
    }, 1000);
  };
  const location = useLocation();
  const path = location.pathname;
  return (
    <>
      <Helmet>
        <title>Svglobalbusiness | Home</title>
      </Helmet>
      <div
        className="landingPage"
        onWheel={path==='/' && handleOnWheel}
      >
        <Layout>
          <div className="description">
            <div className="col-sm-12 col-md-8 col-lg-8 p-0">
              <h1 className="TisaOT fw-bold">Welcome </h1>
              <h2 className="TisaOT">To Silicon Valley's</h2>
              <p className="AkkuratLight">Impactful Digital Design and Development Evolution Firm.We are a team of ingenious developers, designers, marketing experts, and strategists located in the heart of Silicon Valley that will transform your digital ambitions into reality.</p>
            </div>
          </div>
          <Link to="/services" className="explorButton">
            <span>Explore</span>
          </Link>
        </Layout>
      </div>
    </>
  );
};
