import "./App.scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/Routes";
import { useEffect, useState } from "react";
import NewsLetterAlert from "./components/Modals/NewsLetterAlert/NewsLetterAlert";
export const App = () => {
  const [openNewsLetterAlert, setOpenNewsLetterAlert] = useState(false);
  const handleOpenEmailAlert = () => {
   setOpenNewsLetterAlert(true);
  };
  const handleClose = () => {
    localStorage.setItem('popup', JSON.stringify(true))
     setOpenNewsLetterAlert(false);
  };
  useEffect(() => {
    window.innerWidth > 968 && localStorage.getItem('popup') ===null && setTimeout(() => {
      handleOpenEmailAlert("newsletter");
    }, 10000);
  }, []);

  return (
    <>
      <NewsLetterAlert open={openNewsLetterAlert} handleClose={handleClose} />
      <RouterProvider router={router} />
    </>
  );
};
