//For Organic Seo
export const advantage = [
  {
    title: "More Organic Traffic",
    des: "SVGB is a Fremont-based SEO company that is sue-bound to get more organic traffic for your local business. Organic traffic is far more influential than paid traffic since it has the search intent for the services or the products your business is offering.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Increased ROI",
    des: "ROI is the ultimate goal of local businesses, and a well-configured SEO strategy of SVGB will increase the ROI irrespective of the local competitors. ROI will skyrocket with the implementation of high-end search engine optimization services.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Higher Sales, Leads, And Customer Retention",
    des: "Higher organic traffic from a customized search engine optimization plan will bring onboard more leads and sales. Another key benefit of organic search ranking is that it has a higher customer retention ratio which will result in the continuous growth.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Enhanced Brand Awareness and Authority",
    des: "A business reputation is of utmost importance, and SVGB helps local businesses in Fremont to get on top of the organic search results. Consequently, this builds the business authority when they see it listed on top against a search query.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
];
export const quantitative = [
  {
    title: "Small-Scale Business SEO",
    des: "A dynamic formulation to hit the SEO road will widen a customer base of a small-scale business.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Large Business SEO",
    des: "Market leaders in any field require a customized and comprehensive SEO pattern to stay on top of the organic rankings.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Local SEO",
    des: "Scales your business position in the local sector and yields a higher return on investment (ROI).",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "International SEO",
    des: "A global SEO framework will put you in front of the line to get more growth throughout the world.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Mobile SEO",
    des: "The user will get a similar high-end experience when viewing your business listing on mobile devices.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
];
export const seo_Agency = [
  {
    title: "Research",
    des: "SVGB follows a continuous research pattern that keeps on updating the SEO mechanisms to stay ahead of competitors at every stage.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Analysis",
    des: "We analyze every aspect of SEO ranking factors and their applicability scope to formulate an unmatched SEO plan of action.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Strategy",
    des: "SVGB defines and executes a transparent and white-label strategy that is instrumental in increasing the organic search rankings sharply.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Optimization",
    des: "SEO experts of Fremont-based SVGB optimize the client’s websites at par with the white-hat optimizing policies of Google to drive high organic traffic.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Tracking",
    des: "We monitor and track the implemented SEO strategies, and it is a self-accountability stage that helps us to achieve the highest organic results.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Progress Reporting",
    des: "SVGB has a diversified policy of transparency, and we share every nitty-gritty of the SEO framework in a bi-monthly report.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
];
export const seo_Fremont = [
  {
    title: "Drive Organic Traffic",
    des: "SVGB constitutes a jam-packed SEO plan for driving organic traffic.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "SEO Specialists",
    des: "SVGB SEO specialists are among the top experts in the industry.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Higher ROI",
    des: "Our proven SEO services in Fremont are 100% effective in generating a higher ROI.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Certified Google Partners",
    des: "We are a proud team of Certified Google Partners.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Proven Track Record",
    des: "We have a proven track record of increasing the authority of local businesses.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Transparent Reporting",
    des: "SVGB considers transparency the most important factor while reporting SEO search results to clients.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
];
//id web For Website Design and Development
export const webMechanism = [
  {
    title: "Wireframes",
    des: "Wireframes are an important step in the whole process. These represent a connected framework of the complete website. It describes the working and connectivity representation of the website.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Quality Assurance and Testing",
    des: "SVGB is no exception when it comes to quality assurance. We have entrusted a specialized department for quality assurance and testing of web development projects. We run multiple tests to ensure that everything is pitch-perfect.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Idea",
    des: "The idea is the first and foremost step of our web development strategy. We visualize the client’s requirements and frame an organogram in the form of the project idea. This idea acts as a foundational component for the steps following.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Design",
    des: "SVGB uses the latest frameworks and technologies for creating highly intuitive and visually appealing designs for websites. Our radical approach toward design makes us one of the top front-end developer companies in Fremont.\n\n",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Content",
    des: "We create high-quality and SEO-optimized content for websites that will ensure top rankings on Google when searched against the relevant keywords. Moreover, we will also ensure that the content is reader-friendly and knowledgeable.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Development",
    des: "Once the design portion is finalized, the development phases kick off. In this phase, all the functional aspects of the website are operationalized per the client’s requirements to create a dynamic website.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Information Collection",
    des: "Information regarding the functional and design aspects of a website helps us to realize the skeleton of the website. This step will outline the components required to handle the collected bits of information.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Deployment And Launch",
    des: "Once the client's website is ready and passes all the tests, we deploy it to their server. The deployment of a website is the final step, and it launches the website in the digital realm.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
];
//id content For Content Writing
export const contentMechanism = [
  {
    title: "Dynamic & Multi-Dimensional Research",
    des: "A well-researched content forms top-quality content. Any topic or content idea needs to be discussed from multiple dimensions to get the best facts and figures to support your words. In-depth research will pave the way for user-friendly and info-rich content.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Competitor Analysis & Identifying Target Audience",
    des: "Every piece of content has a targeted audience and tons of different competitors. Therefore, it is ideal for keeping these two factors in consideration. The identification of the target audience and competitors will narrow down the content management process.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Understanding Client’s Requirements",
    des: "The first step of SVGB’s content writing methodology is to comprehend the client’s requirements. Based on these fundamental requirements, one can easily chalk out the roadmap of a dynamic content creation strategy.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Creating Content Strategy",
    des: "A content outline serves as the head starts for highly engaging and dynamic content. Content strategy will provide a basic framework to create top-quality content in line with the client’s requirements, competitors, and targeted audience.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "SEO Configuration of Content",
    des: "The optimization phase takes place after writing the content. SEO configuration is to check the optimum density and uniform distribution of keywords within a piece of content. Moreover, the URLs, images, and headings are also optimized in terms of on-page SEO practices.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Content Writing",
    des: "Based on our dynamic research, keyword listing, target audience analysis, and competitor analysis, we will create professional, error-free, and non-plagiarized content that will get higher organic traffic. Consequently, you will have higher customer retention rates for your business.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "Revisions And Updates",
    des: "Revisions and updates are the final strokes of highly professional content writing services. Revising the content will highlight errors, typography mistakes, and structural errors. We update the content after reviewing it multiple times. Resultantly, the quality parameters increase markedly.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    title: "SEO Keyword Research",
    des: "Keyword research is the foundational component of a content-writing mechanism. Keywords are the lifeline of content and determine the flow of content for the targeted audience and competitors. Researching relevant keywords will boost the SEO aspect and will yield higher organic traffic.",
    col: "col-sm-12 col-md-6 col-lg-4",
  },
];
export const contentServices = [
  {
    title: "Professional & Skilled Content Writers",
    des: "At SVGB, we have a very complex internal process for recruiting content writers. That makes us a team of highly professional and dynamic content writers who work in line with client's requirements to deliver the desired results.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Multi-Dimensional Expertise",
    des: "SVGB is a proud team of content writers who are adept in thousands of content niches and can deliver high-quality content irrespective of the industry. We have honed our skills in a way to elevate our content expertise.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "Quick Turnaround Time",
    des: "Time management is our utmost priority, and we tend to deliver projects within the stipulated timeframes. Our time-effective project management will win the clients’ trust and will help in developing long-term relationships.",
    col: "col-sm-12 col-md-6 col-lg-6",
  },
  {
    title: "100% Plagiarism-Free",
    des: "When AI content and bots are ruining the statistics, we believe in the human effort of content creation. Our content is free from plagiarism, as every single word comes out from the thought process of our content writers.",
    col: "col-sm-12 col-md-6 col-lg-6",
  }
];