import "./style.scss";
import HorizontalScroll from "react-scroll-horizontal";

import { Link, useNavigate } from "react-router-dom";
import { record } from "./data";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Layout } from "../../pages/Layout";
import { Helmet } from "react-helmet";
export const Portfolio = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Svglobalbusiness | Portfolio</title>
      </Helmet>
      <Layout>
        <div class="portfolio">
        <div className="col-12 position-relative">
                <p className="EurostileExt">
                  You are now viewing <Link to="/">Home</Link>
                  <span> / </span>
                  <Link to="/portfolio">Portfolio</Link>
                </p>
              </div>
          {/* <a href={`/contact`} className="text-light fs-1 text-decoration-none"> */}
          <KeyboardBackspaceIcon onClick={() => navigate(-1)} className="my-3 fs-2" style={{ cursor: "pointer" }} />
          {/* </a> */}
          <HorizontalScroll reverseScroll={true} pageLock>
            {record?.map((record, index) => (
              <div className="picture position-relative" key={index}>
                <img src={record?.img} alt="img" />
                {/* <video
              src={record.videoLink}
              alt="video"
              onMouseOver={(e) => {
                e.target.play();
              }}
              onMouseOut={(e) => e.target.pause()}
            ></video> */}
                <div className="position-absolute des">
                  <h3 className="text-center">{record.title}</h3>
                  {/* <p>{record.des}</p>
              <h6>{record.category}</h6> */}
                </div>
              </div>
            ))}
          </HorizontalScroll>
        </div>
      </Layout>
    </>
  );
};
