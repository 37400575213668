import {  fadeInUp } from "react-animations";
import Radium from "radium";
import Rosa from "react-on-scroll-animation";

export const InfoCard = ({aboutData}) => {
  return (
    <>
              {aboutData[0]?.edgeData.map((record,key) => (
                <Rosa disable="mobile" animation="fade-up" duration={200} delay={100} className="col-sm-12 col-md-6 col-lg-6" key={key}>
                  <div style={{ animation: "x 2s", animationName: Radium.keyframes(fadeInUp, "bounch") }} className="card card1 rounded-3 py-4 px-5">                    
                    <h3 className="TisaOT mb-3">{record.title}</h3>                                        
                    <p style={{ animation: "x 3.5s", animationName: Radium.keyframes(fadeInUp, "bounch") }} className="AkkuratLight">{record.description}</p>                    
                  </div>
                </Rosa>
              ))}

    </>
  )
}
