import { Alert } from '@mui/material'
import './style.scss'

const AlertModal = ({status,msg}) => {
  return (
    <Alert severity={status} className='alert'>{msg}</Alert>
  )
}

export default AlertModal
