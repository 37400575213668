import React from "react";
import "./style.scss";
import { fadeInRight, bounceInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Link, useLocation } from "react-router-dom";
import { data } from "../componentData";
const animate = {
  fadeInRight: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRight, "fadeIn"),
  },
  bounceInUp: {
    animation: "x 3s",
    animationName: Radium.keyframes(bounceInUp, "bounch"),
  },
};
export const MobileNavbar = () => {
  // ServerName
  const serverName = `${window.location.protocol + "//" + window.location.host}`;
  const location = useLocation();
  return (
    <StyleRoot>
      <div style={animate.fadeInRight} className="mobileNavbar">
    <div className="container">
    <Link to='/'><img src={`${serverName}/assets/Pictures/logo.webp`} alt="logo" className="logo"/></Link>
    </div>
        <div className="mobileNav">
        <div className="container d-flex flex-column justify-content-between">
        {data[0]?.navbar.map((record,index) => (
            <div className="d-flex align-items-end" key={index}>
            {index!==0 && <i className="fa fa-angle-right text-white me-2"></i>}
              <div style={animate.bounceInUp} className={`d-flex flex-column nav `}>
                <span className="mb-0">{record.num}</span>
                <Link className={`${location.pathname === record.link ? 'active':''}`} to={record.link} >{record.navItem}</Link>
              </div>
            </div>
          ))}
        </div>          
        </div>
      </div>
    </StyleRoot>
  );
};
