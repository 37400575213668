import "./style.scss";
import { Link } from "react-router-dom";
import {Layout} from "../Layout";
import { aboutData } from "./aboutData";
import { fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { InfoCard } from "../../components/InfoCard/InfoCard";
import Rosa from "react-on-scroll-animation";
import { Helmet } from "react-helmet";
export const About = () => {
  return (
    <>
       <Helmet>
        <title>Svglobalbusiness | About</title>
      </Helmet>
      <StyleRoot>
      <Layout>
        <div className="about">
          <div className="subcontainer row">
            <div className="col-12 position-relative">
              <p className="EurostileExt">
                You are now viewing <Link to="/">Home</Link>
                <span> / </span>
                <Link to="/about">About Us</Link>
              </p>
              <div className="mt-5 content">
                <div className="aboutSection">
                  <h1 className="TisaOT">About Us</h1>
                  <p className="AkkuratLight pb-2">Our services spectrum is diverse covering major aspects of web development and designing. Every member of our team follows the laid-out principles of quality adherence while working on different projects. We have the following skillset along with a dedicated team.</p>
                  <Link className="button" to={"/contact"}>
                    GET IN TOUCH
                  </Link>
                </div>
                <div className="mainBoxAbout mt-5">
                  <div className="mainBoxContent d-flex">
                    <div className="subMainBoxContent">
                      <h1>
                        We are An Affluently <span style={{ color: "#02b0f1" }}>Adaptive</span> Organization                       
                      </h1>
                      <p>From our early years to a leading organization in Silicon Valley, SVGlobalBusiness has adhered to the motto of progressive development.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Experience */}
          <Rosa disable="mobile" animation="fade-up" duration={300} delay={100}>
            <div className="mainBoxAbout mt-5  py-5">
              <div className="row">
                <div className="subMainBoxContent col-sm-12 col-md-12 col-lg-5">
                  <Rosa disable="mobile" animation="fade-up" duration={300} delay={100}>
                    <h1 className="TisaOT fw-bold mb-3">Where Do We Stand Right Now?</h1>
                  </Rosa>
                  <Rosa disable="mobile" animation="fade-up" duration={400} delay={100}>
                    <p className="AkkuratLight">SVGlobalBusiness has a tradition of exceeding the client's requirements. In turn, we take great pride in finishing committed projects at par with the quality standards.</p>
                  </Rosa>
                </div>

                <div style={{ animation: "x 3s", animationName: Radium.keyframes(fadeInUp, "bounch") }} className="col-sm-12 col-md-12 col-lg-7 m-auto text-end">
                  <div className="row justify-content-md-center justify-content-center">
                    {aboutData[0]?.counterData.map((record, key) => (
                      <Rosa disable="mobile" animation="fade-up" duration={400} delay={100} className="counterCircle" key={key}>
                        <strong className="TisaOT">{record.exp}</strong>
                        <br />
                        <span>{record.des}</span>
                      </Rosa>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Rosa>
          {/* //Edges */}
          <Rosa disable="mobile" animation="fade-up" duration={300} delay={100}>
          <div className="edge">
            <Rosa disable="mobile" animation="fade-up" duration={300} delay={100}>
              <h1 className="text-center my-5 TisaOT fw-bold">Embedding Diverse Operating Model</h1>
            </Rosa>
            <div className="row">
              <InfoCard aboutData={aboutData} />
            </div>
          </div>
          </Rosa>
          {/* Directors */}
          <Rosa disable="mobile" animation="fade-up" duration={300} delay={100}>
            <div className="mainBoxAbout mt-5 py-5">
              <Rosa disable="mobile" animation="fade-up" duration={300} delay={100}>
                <h1 className="text-center mb-5 TisaOT">Management Team</h1>
              </Rosa>
              <div className="row director">
                {aboutData[0]?.directorData.map((record, key) => (
                  <div className="col-sm-12 col-md-6 col-lg-3" key={key}>
                    <Rosa disable="mobile" animation="fade-up" duration={300} delay={100}>
                      <div className="card card2  rounded-3 py-4 px-3 text-center mb-4">                      
                        <img src={`${record.img}`} alt="img" />                                              
                          <h4 className="TisaOT mb-3">{record.title}</h4>                                                
                          <p className="AkkuratLight fs-6">{record.description}</p>                        
                        <div className="footer text-center">                          
                        </div>
                      </div>
                    </Rosa>
                  </div>
                ))}
              </div>
            </div>
          </Rosa>
        </div>
      </Layout>
    </StyleRoot>
    </>
    
  );
};
