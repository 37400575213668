import { Link } from "react-router-dom"

export const Cta = ({title, startButton, contactButton}) => {
  return (
    <div className="contactBox my-5  py-5 text-center">
    <h3>{title}</h3>
    <Link to={"/contactmeeting"}><button className="contactButton">{startButton}</button></Link>
    <Link to={"/contact"}><button className="contactButton">{contactButton}</button></Link>
  </div>
  )
}
