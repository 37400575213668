import { Box, Modal } from "@mui/material";
import "./style.scss";
import Radium, { StyleRoot } from "radium";
import { bounceInUp } from "react-animations";
import { useRef } from "react";
import { useState } from "react";
import AlertModal from "../../Alert/AlertModal";
const animate = {
  bounceInUp: {
    animation: "x 3s",
    animationName: Radium.keyframes(bounceInUp, "bounce"),
  },
};

const NewsLetterAlert = ({ open, handleClose }) => {
  const serverName = `${window.location.protocol + "//" + window.location.host}`;

    const value = {
      send: "Subscribe",
      modalAlert: false,
      msg: "",
      status: "",
    };
  const [data, setData] = useState(value);
  const user_email = useRef();
  const onSubmitHanlder = async (e) => {
    e.preventDefault();
    setData({ ...data, send: "Sending" });
    const updatedEmailBody = {
      SecureToken: process.env.REACT_APP_EMAIL_TOKEN,
      To: "waheedahmad99038@gmail.com",
      From: "waheedahmad99038@gmail.com",
      Subject: `Contact for NewsLetter`,
      Body: `Email  :${user_email.current.value}<br/>`,
    };
    try{
    await window.Email.send(updatedEmailBody);
    setData({ ...value, status: "success", msg: "Successfully, Email sent !", modalAlert: true });
    clearValues(e.target.reset());
    handleClose()
  } catch (error) {
    setData({ ...data, send: "Send", status: "error", msg: "Try again, Email did not sent !", modalAlert: true });
    clearValues();
    handleClose()
  }
};
const clearValues = () => {
  setTimeout(() => {
    setData({ ...value, modalAlert: false });
  }, 3000);
};
  return (
    <>
       {data.modalAlert && <AlertModal status={data.status} msg={data.msg} />}
       <StyleRoot>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box className="newsLetteralert" style={animate.bounceInUp}>
          <form onSubmit={onSubmitHanlder}>
            <span className="crossLetter" onClick={handleClose}>
              X
            </span>
            <div className="newsletterdes">
              <img src={`${serverName}/assets/Pictures/newsletter.jpg`} alt="newsletter" />
              <div className="w-75">
                <h5 className="TisaOT ">Subscribe To Our Newsletter</h5>
                <p className="AkkuratLight">Join our mailing list to receive the latest news and updates from our team.</p>
              </div>
            </div>
            <div className="subScribe bg-white mt-5">
              <input ref={user_email} type="email" placeholder="Enter email" />
              <button>{data.send}</button>
            </div>
          </form>
        </Box>
      </Modal>
    </StyleRoot>
    </>
  );
};

export default NewsLetterAlert;
