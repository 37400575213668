import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DesktopNavbar } from "../components/DesktopNavbar/DesktopNavbar";
import { Footer } from "../components/Footer/Footer";
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar";
import Subheader from "../components/Subheader/Subheader";
export const Layout = ({ children }) => {
  // ServerName
  const serverName = `${window.location.protocol + "//" + window.location.host}`;
  const [toggleNavbar, setToggleNavbar] = useState(true);
  const location = useLocation();
  const path = location.pathname;
  const styleMenu = {
    background: path === "/" ? "" : "#02b0f1",
  };

  const [offset, setOffset] = useState(0);
  const onScroll = () => setOffset(window.pageYOffset);
  useEffect(() => {
    //Condition for canvas
    document.body.style.backgroundImage = path === "/" ? "linear-gradient(145deg, #0f1012, #02b0f1)" : "#fff";
    document.body.style.overflow = path === "/" ? "hidden" : "";
    document.body.style.backgroundPosition = path === "/" ? "center" : "";
    document.body.style.backgroundRepeat = path === "/" ? "no-repeat" : "";
    document.body.style.backgroundSize = path === "/" ? "cover" : "";
    document.body.style.height = path === "/" ? "100vh" : "";
    document.querySelector(".particles-canvas").style.position = path === "/" ? "relative" : "fixed";
    window.scrollTo(0, 0);
  }, [path]);
  return (
    <>
     <Link to={`/contact`}>
        <Button className="headerButton contactButton text-capitalize">Contact Us</Button>
      </Link>
      {path !== "/" && <Subheader />}
      <div className="layout row" onWheel={onScroll}>
        <div className="col-1"></div>
        <div className="col-10">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <img src={path === "/" ? `${serverName}/assets/Pictures/logo.webp` : `${serverName}/assets/Pictures/logo2.png`} className="logo" alt="logo" style={{ marginLeft: "-0.9rem", marginTop: "2rem" }} />
            </Link>
            {
              <div className="container-width">
              <span>
              <Link to="/contactmeeting">
                <button className="headerButton btn btn-sm me-2 text-capitalize">Schedule A Meeting</button>
              </Link>
              </span>
              <span>
              <Link to="/seoauditcontact">
                  <button className="headerButton btn btn-sm text-capitalize">Get a Free SEO Audit</button>
                </Link>
              </span>
            </div>}
          </div>
          {children}
          {path !== "/" && <Footer />}
        </div>
        {!toggleNavbar && (
          <>
            <DesktopNavbar toggleNavbar={toggleNavbar} /> <MobileNavbar toggleNavbar={toggleNavbar} />
          </>
        )}
        <div className="col-1  position-relative" style={{ height: "100vh" }}>
          <div className="toggleMenu">
            {toggleNavbar ? (
              <div className="menu" onClick={() => setToggleNavbar(!toggleNavbar)}>
                <span className="EurostileExt" style={{ color: path === "/" ? "" : "#02b0f1", fontSize: "10px", letterSpacing: "3px" }}>
                  MENU
                </span>
                <div style={styleMenu}></div>
                <div style={styleMenu}></div>
                <div style={styleMenu}></div>
              </div>
            ) : (
              <div className="close" onClick={() => setToggleNavbar(!toggleNavbar)}>
                <span>Close</span>
                <div></div>
                <div></div>
              </div>
            )}
          </div>
          {path === "/" ? (
            ""
          ) : (
            <>
              {offset >= 343 ? (
                <div
                  className="scroll d-flex flex-column align-items-baseline text-center"
                  style={{ width: "5rem" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOffset(0);
                  }}
                >
                  <span className="EurostileExt" style={{ color: path === "/" ? "" : "#02b0f1", fontSize: "10px", letterSpacing: "3px" }}>
                    BACK TO TOP
                  </span>
                </div>
              ) : (
                <div className="scroll d-flex flex-column align-items-baseline" onClick={() => window.scrollTo(1000, 1000)} style={{ width: "1.1rem" }}>
                  <span className="EurostileExt" style={{ color: path === "/" ? "" : "#02b0f1", fontSize: "10px", letterSpacing: "3px", marginLeft: "-1.7rem" }}>
                    SCROLL
                  </span>
                  <img src={`${serverName}/assets/Pictures/scrollDown.svg`} alt="scroll" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
