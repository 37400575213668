

export const subProductData = [
  {
    data: [
      {
        id: "website-development",
        singlePage:'',
        Category:'Web Development',
        title: "Full stack development",
        des: "Experience the power of Full Stack Development with us! From crafting seamless user interfaces to implementing robust server-side functions, our expert developers cover every aspect of web development. Elevate your business with our comprehensive and well-rounded solutions for a standout online presence.\n\n\n",
      },
      {
        id: "website-development",
        singlePage:'',
        Category:'Web Development',
        title: "Full stack front end development",
        des: "Introducing our expertise in Full Stack Front-End Development, where we develop visually attractive websites using HTML, CSS, and JavaScript. Elevate your online presence with our services—optimized page load times, responsive designs, and enhanced user engagement for a standout digital experience.\n\n\n",
      },
      {
        id: "website-development",
        singlePage:'',
        Category:'Web Development',
        title: "Mern-Stack Development",
        des: "Explore the dynamic world of MERN Stack Development with us! Harnessing the strengths of MongoDB, Express.js, React, and Node.js, we create high-performing web applications. Our MERN competencies include mastery in React.js, Express.js, Node.js, and MongoDB, ensuring a seamless and powerful development journey for your web projects.\n\n\n",
      },

      // {
      //   id: "website-development",
      //   singlePage:'',
      //   Category:'Web Development',
      //   title: "Full Stack Software Development",
      //   des: "Full Stack Software Development seamlessly integrates both front-end and back-end expertise to create websites and applications that are not only visually appealing but also optimized for search engines. Our team's comprehensive approach ensures that your digital products not only function flawlessly but also rank higher in search results, driving organic traffic and enhancing your online presence.\n\n\n",
      // },
      // {
      //   id: "website-development",
      //   singlePage:'',
      //   Category:'Web Development',
      //   title: "Full Stack Application Development",
      //   des: "Full Stack Application Development empowers businesses to create versatile, end-to-end software solutions that cater to diverse user needs. Our experts harness the full spectrum of technology, from front-end interfaces to back-end infrastructure, ensuring robust, responsive, and SEO-friendly applications that excel in today's digital landscape.\n\n\n",
      // },

      // {
      //   id: "website-development",
      //   singlePage:'',
      //   Category:'Web Development',
      //   title: "Full-Stack JavaScript Development",
      //   des: "Unlock the potential of Full-Stack JavaScript Development to build dynamic web applications that seamlessly blend client and server-side technologies. Our experts harness the power of JavaScript across the entire stack, delivering fast, interactive, and SEO-optimized digital solutions. Elevate your online presence and user engagement with our Full-Stack JavaScript Development expertise.\n\n\n",
      // },
      // {
      //   id: "website-development",
      //   singlePage:'',
      //   Category:'Web Development',
      //   title: "API Development And Integration",
      //   des: "API Development and Integration is the key to streamlining digital ecosystems. Our experts craft custom APIs and seamlessly integrate them into your software, enabling data sharing and functionality across applications. Enhance your online presence and user experience with our API Development and Integration services.\n\n\n",
      // },
      // {
      //   id: "website-development",
      //   singlePage:'',
      //   Category:'Web Development',
      //   title: "Full Stack PHP Development",
      //   des: "Experience the power of Full Stack PHP Development, where we combine front-end finesse and robust back-end solutions using PHP to create dynamic and SEO-friendly web applications. Our expert team ensures your digital projects not only look great but also perform optimally in search engine rankings, driving organic traffic.\n\n\n",
      // },
      //For mobile app
      {
        id: "mobile-app-development",
        singlePage:'',
        Category:'Mobile App Development',
        title: "React Native Development",
        des: "Unlock the potential of React Native Development—shape cross-platform mobile apps with a native feel using JavaScript, Redux, Firebase, RESTful APIs, and UI libraries like Material-UI and Ant Design, enhanced with features like One Signal, FCM, and Geolocation.\n\n\n",
      },
      {
        id: "mobile-app-development",
        singlePage:'',
        Category:'Mobile App Development',
        title: "App Support and Maintenance",
        des: "Ensure your app's longevity and peak performance with our top-notch support and maintenance services. Our expert team proactively tackles issues, enhancing user satisfaction and retention. Partner with us for hassle-free app upkeep and enjoy worry-free success.\n\n\n",
      },

      {
        id: "mobile-app-development",
        singlePage:'',
        Category:'Mobile App Development',
        title: "IOS App Development",
        des: "Discover the power of iOS with our skilled app development services. We design modern, user-friendly apps tailored to your specific needs. Boost your brand's presence and interaction in the Apple world with our high-quality solutions.\n\n\n",
      },
      {
        id: "mobile-app-development",
        singlePage:'',
        Category:'Mobile App Development',
        title: "Android App Development",
        des: "Boost your brand's visibility on Android with our professional app development services. We're experts in creating creative and user-friendly apps customized to your needs. Partner with us to enhance engagement and drive growth.\n\n\n",
      },
      {
        id: "mobile-app-development",
        singlePage:'',
        Category:'Mobile App Development',
        title: "Cross-Platform Development",
        des: "Unleash the power of reaching everyone, everywhere! Dive into our world of cross-platform app development, where our expert team crafts dynamic apps that effortlessly adapt to different devices and platforms. Elevate your development journey and captivate a wider audience with our irresistible cross-platform expertise.\n\n\n",
      },
      {
        id: "mobile-app-development",
        singlePage:'',
        Category:'Mobile App Development',
        title: "Hybrid App Development",
        des: "Supercharge your mobile impact with our hybrid app development services! We're masters at creating adaptable apps that seamlessly conquer different platforms. Partner with our hybrid app experts to enhance efficiency and broaden your audience reach. Let's elevate your mobile presence together!\n\n\n",
      },
      {
        id: "mobile-app-development",
        singlePage:'',
        Category:'Mobile App Development',
        title: "Flutter Development",
        des: "Experience the magic of Flutter Development—craft visually stunning and feature-rich mobile apps using Flutter, Dart, and custom reusable components. Elevate your app with Firebase, real-time tracking, SDK integration, payment functionality, and hardware access. Our meticulous process ensures your app is optimized for peak performance, usability, and scalability, setting your business apart in the competitive market.\n\n\n",
      },

      //For Shopify and Wordpress
      {
        id: "wordPress-shopify-development",
        singlePage:'',
        Category:'WordPress & Shopify Development',
        title: "WordPress Development",
        des: "We create cool and easy-to-manage custom WordPress websites. Your site looks awesome, and you can handle it hassle-free.\n\n\n",
      },
      {
        id: "wordPress-shopify-development",
        singlePage:'',
        Category:'WordPress & Shopify Development',
        title: "Shopify Development",
        des: "We build personalized Shopify solutions for a smooth and friendly online shopping experience. Your store not only looks great but also works like a breeze for you and your customers.\n\n\n",
      },
      //For Block Chain
      {
        id: "blockchain-development",
        singlePage:'',
        Category:'Blockchain Development',
        title: "Blockchain Application Development",
        des: "Step confidently into a new era with SVGB's Block chain Application Development. Our custom solutions redefine the way you operate, ensuring heightened security, unmatched transparency, and streamlined efficiency. Your business, revolutionized.\n\n\n",
      },
      {
        id: "blockchain-development",
        singlePage:'',
        Category:'Blockchain Development',
        title: "Smart Contract Development",
        des: "Experience the future of business automation with SVGB's Smart Contract Development. Our robust smart contracts are tailor-made to automate and streamline your processes. Efficiency, reliability, and innovation combined to empower your business.For explore click below button: \n\n\n",
      },
      // {
      //   id: "blockchain-development",
      //   singlePage:'',
      //   Category:'Blockchain Development',
      //   title: "Decentralized App (DApp) Development",
      //   des: "Embrace the decentralized future by harnessing the power of blockchain in application development. Our DApp development services create innovative, user-friendly decentralized applications, offering enhanced security, transparency, and user control in various industries.\n\n\n",
      // },
      // {
      //   id: "blockchain-development",
      //   singlePage:'',
      //   Category:'Blockchain Development',
      //   title: "Blockchain Security Audits",
      //   des: "Safeguard your blockchain infrastructure with our security audit services. Our experts meticulously assess and fortify the security of your blockchain applications and smart contracts, ensuring protection against vulnerabilities and unauthorized access, and maintaining the integrity of your decentralized ecosystem.\n\n\n",
      // },
      //For AI Integration Services
      {
        id: "ai-integration-services",
        singlePage:'',
        Category:'AI Integration Services',
        title: "Custom AI Solutions",
        des: "Embark on a journey of innovation with our tailor-made AI solutions, specifically crafted to address and conquer the unique challenges faced by your business.\n\n\n",
      },
      {
        id: "ai-integration-services",
        singlePage:'',
        Category:'AI Integration Services',
        title: "Automation Integration",
        des: "Effortlessly enhance efficiency by integrating AI-driven automation into your processes. Watch as manual efforts decrease and errors become a thing of the past, allowing your team to focus on what truly matters.\n\n\n",
      },
      {
        id: "ai-integration-services",
        singlePage:'',
        Category:'AI Integration Services',
        title: "AI Tools Integration",
        des: "Elevate your business strategy by seamlessly incorporating advanced AI tools. Gain invaluable insights, optimize decision-making processes, and create unparalleled customer experiences, all powered by cutting-edge artificial intelligence.With SVGB's AI integration services, unlock the full potential of artificial intelligence and stay ahead in today's competitive landscape. Embrace innovation with us!\n\n\n",
      },
      // {
      //   id: "ai-integration-services",
      //   singlePage:'',
      //   Category:'AI Integration Services',
      //   title: "Robotic Process Automation (RPA)",
      //   des: "Streamline your business processes with Robotic Process Automation integration. Our services implement intelligent bots that automate repetitive tasks, reducing human error, improving efficiency, and freeing up valuable resources to focus on strategic initiatives.\n\n\n",
      // },


    ],
  },
];
