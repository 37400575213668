import { Link, useParams } from 'react-router-dom';
import {Layout} from '../Layout';
import  {subProductData} from './data';
import './style.scss';
import Rosa from "react-on-scroll-animation";
import { productData } from '../Products/data';
import { Helmet } from 'react-helmet';
export const SubProducts = () => {
  const params = useParams();
  const tab =  params.id;
  const filterData = subProductData[0].data.filter((x) => x.id ===tab )
  const product = productData.filter((x)=> x.id===tab)


  return (
    <>
    <Helmet>
        <title>Svglobalbusiness | {product[0].title}</title>
    </Helmet>
    <Layout>
        <div className="subproducts">
          <div className="subcontainer row">
            <div className="col-12 position-relative">
              <p className="EurostileExt">
                You are now viewing <Link to="/">Home</Link>
                <span> / </span>
                <Link to="/services">Services</Link>
                <span> / </span>
                <Link to={`/subproducts/${filterData[0].id}`}>{product[0].title}</Link>
              </p>
            </div>
            <h1 className="TisaOT">{product[0].title} Services</h1>
            {/* //Edges */}
          <div className="edge mt-3">
            <div className="row justify-content-center">
              {filterData?.map((record,index) => (
                <Rosa key={index} disable="mobile" animation="fade-up" duration={300} delay={300} className="col-sm-12 col-md-12 col-lg-6">
                  <div className="card card1 rounded-3">                  
                    <h2 className="TisaOT mb-4">{record.title}</h2>                                    
                    <p className="AkkuratLight fs-4">{record.des}</p>                  
                    <div className="text-center my-2">
                      {record.singlePage && <Link to={`/singleProduct/${record.singlePage}`} className='button me-2 mb-2'> EXPLORE </Link>}
                      <Link to={'/contact'} className='button me-2 mb-2'> LET'S DO IT </Link>

                    </div>                    
                  </div>                
                </Rosa>
              ))}
            </div>
          </div>
          </div>         
        </div>        
      </Layout>
    </>
  )
}
