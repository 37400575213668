import { Link } from "react-router-dom";
import { Layout } from "../Layout";
import { productData } from "./data";
import "./style.scss";
import { fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import Rosa from "react-on-scroll-animation";
import { Helmet } from "react-helmet";

export const Products = () => {
  return (
    <>
      <Helmet>
        <title>Svglobalbusiness | Services</title>
      </Helmet>
      <StyleRoot>
        <Layout>
          <div className="products" style={{ animation: "x 1s", animationName: Radium.keyframes(fadeInUp, "bounch") }}>
            <div className="subcontainer row">
              <div className="col-12 position-relative">
                <p className="EurostileExt">
                  You are now viewing <Link to="/">Home</Link>
                  <span> / </span>
                  <Link to="/services">Services</Link>
                </p>
              </div>
              <h1 className="TisaOT">Services At SVGB</h1>
              {/* //Edges */}
              <div className="edge mt-3">
                <div className="row">
                  {productData.map((record, key) => (
                    <div className="col-12" key={key}>
                      <Rosa disable="mobile" animation="fade-up" duration={300} delay={100} className="card card1 rounded-3">
                        {/* <Rosa animation="fade-up" duration={200} delay={200}> */}
                        <h2 className="TisaOT mb-4">{record.title}</h2>
                        {/* </Rosa>                       */}
                        <p className="AkkuratLight fs-4">{record.des}</p>
                        <div className="d-flex flex-wrap">
                          <Link to={`/${record.id==='content' ? 'singleProduct' : 'subproducts'}/${record.id}`} className="button me-2 mb-2">
                            EXPLORE
                          </Link>
                          <Link to={"/contact"} className="button2 me-2 mb-2">
                            LET'S DO IT
                          </Link>
                        </div>
                      </Rosa>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </StyleRoot>
    </>
  );
};
