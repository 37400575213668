export const productData = [
    {
        id : 'website-development',
        title : 'Web Development' ,
        des : "A business's first impression is its website. Understanding Website Development is key and staying in sync with the latest trends is vital for effective website creation. Modern websites focus on visuals over excessive content. \n\nUnlock the potential of your digital presence with our Website Development expertise! We craft websites from concept to reality, ensuring a visually captivating and trend-forward online identity. Your website is the first impression. So, make it count with our modern and impactful approach to development!"
    },
    {
        id : 'mobile-app-development',
        title : 'Mobile App Development ' ,
        des : "At Silicon Valley Global Business, we turn your mobile app dreams into reality. Explore our skilled developers specialize in:"
    },
    {
        id : 'wordPress-shopify-development',
        title : 'WordPress and Shopify Development' ,
        des : "Discover excellence in online presence with our WordPress and Shopify development services. Whether you're launching an e-commerce store or a professional blog, our team excels in delivering...."
    },
    {
        id : 'blockchain-development',
        title : 'Blockchain Development' ,
        des : "Welcome to SVGB, where we pave the way for your business to thrive in the future. Explore our cutting-edge Block chain development services designed to elevate your operations, enhance security, and bring efficiency to the forefront......"
    },
    {
        id : 'ai-integration-services',
        title : 'AI Integration' ,
        des : "At Silicon Valley we empower businesses through cutting-edge AI integration solutions. Our skilled team excels in seamlessly incorporating artificial intelligence into your operations, elevating efficiency and productivity. Explore our AI integration services."
    }
]